export default {
  HOME: '/',
  POST_LIST: '/post/list',
  POST_INTEREST_LIST: '/post/interest/list',
  POST_REPORT_LIST: '/post/report/list',
  POST_RECOMMEND_LIST: '/post/recommend/list',
  POST_DETAIL: '/post',
  INTEREST: '/interest/bookmark',
  FOLLOW_LIST: '/follow/list',
  MY_PAGE: '/mypage',
  MY_PAGE_PROFILE: '/mypage/user/edit',
  MY_PAGE_WITHDRAW: '/mypage/user/withdraw',
  MY_PAGE_BUSINESS: '/mypage/business',
  MY_PAGE_BUSINESS_ANALYTICS_OUTLINE: '/mypage/business/analytics/outline',
  MY_PAGE_BUSINESS_ANALYTICS_CONTENTS: '/mypage/business/analytics/contents',
  MY_PAGE_BUSINESS_PROFILE: '/mypage/business/profile',
  MY_PAGE_PASSWORD_CHECK: '/mypage/password/check',
  MY_PAGE_PASSWORD_CHANGE: '/mypage/password/change',
  BUSINESS_CONTENTS: '/business/contents',
  MY_PAGE_BUSINESS_CONTENTS: '/mypage/content/register',
  BUSINESS_PROFILE: '/business/profile',
  INFORMATION: 'https://endurable-fibre-c5c.notion.site/1ba52098bba7403ab20839504a745ccc?pvs=4', // 오운드 소개
  NOTICE: 'https://endurable-fibre-c5c.notion.site/8a510062ff9f4412884cf503a86352df?pvs=4', // 공지사항
  FAQ: 'https://endurable-fibre-c5c.notion.site/6f133755ce29418ba2a6d24e2c8eb9d4?pvs=4', // 자주 묻는 질문
  TERMS: 'https://endurable-fibre-c5c.notion.site/8f078d8d929847aab9c3a2a7454b8803?pvs=4', // 이용약관
  PRIVACY_POLICY: 'https://endurable-fibre-c5c.notion.site/db28e09f48ad4552b7eec7bf2b8d7d60?pvs=4' // 개인정보처리방침
}
